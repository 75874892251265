import { template as template_7c6edba001d6483190dc9439f02c8b3e } from "@ember/template-compiler";
const FKLabel = template_7c6edba001d6483190dc9439f02c8b3e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
