import { template as template_210c6073450e4dbd98dc46475bc937d4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_210c6073450e4dbd98dc46475bc937d4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
