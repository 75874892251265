import { template as template_5f1152a35fba4bcda02f9520cedfe4ad } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_5f1152a35fba4bcda02f9520cedfe4ad(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
