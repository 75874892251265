import { template as template_7d902b62aeab448295364834ed75f198 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_7d902b62aeab448295364834ed75f198(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
