import { template as template_de0196656c964e5595b0d9b1c3f17fb2 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_de0196656c964e5595b0d9b1c3f17fb2(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
