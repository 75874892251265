import { template as template_f5ca28a897c644d5910cf948adb2d6b5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f5ca28a897c644d5910cf948adb2d6b5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
